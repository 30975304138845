import {headers, baseURL} from 'src/services/auth';

/**
 *
 * @param surveyId {String} - The id of the survey the response is for
 * @param subject {String} - The email's subject
 * @param fromAddress {String} - What the return email address is
 * @param message {String} - The full email message, with any template tags already replaced
 * @param messageTemplatesId {String} - The id of the template that was selected
 * @param imageUrl {String} - URL of an image attached with the template editor.
 * @return {Promise<any>}
 */
export function sendSocialMail(merchantId, surveyId, actionType, subject, fromAddress, message, messageTemplatesId, imageUrl) {
  // const baseURL = window.INTEGRATIONS_ENGINE_URL; //TODO: V3 endpoint
  // const url = new URL(`${baseURL}v3/api/merchants/${merchantId}/Surveys/${surveyId}/email/${actionType}`); //TODO: V3 endpoint
  const url = new URL(`${baseURL}/v2/api/survey/${surveyId}/social`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      subject: subject,
      from_address: fromAddress,
      message: message,
      message_templates_id: messageTemplatesId,
      image_url: imageUrl
    })
  }).then((result) => {
    return result.json();
  });
}
